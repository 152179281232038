/* eslint-disable */

// @ts-nocheck

import { createContext, ReactNode, useEffect } from "react";
import { IUseAuth, useAuth } from "@/entities/user";
import { Button } from "antd";

const AuthContext = createContext<IUseAuth | null>(null);

export const withAuthProvider = (component: () => ReactNode) => () => {
  const auth = useAuth();
  
  useEffect(() => {
    auth.auth()
  }, []);
  
  return (
    <AuthContext.Provider value={null}>
      <Button onClick={auth.ssoRedirect}>Авторизоваться</Button>
      {component()}
    </AuthContext.Provider>
  );
};
