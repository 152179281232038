/* eslint-disable */

// @ts-nocheck

import { useState } from "react";

export interface IUseAuth {
  user: any;
  auth: Promise<void>;
  ssoRedirect: void;
}

export const useAuth = () => {
  const [user, setUser] = useState<any>(null);
  
  const ssoRedirect = () => {
    const envRecords: Record<string, string> = import.meta.env;
    
    const clientId = envRecords.VITE_SSO_CLIENT_ID;
    const redirectURI = envRecords.VITE_BASE_URI;
    const ssoURI = envRecords.VITE_SSO_URI + '/oauth/authorize';
    const responseType = 'code';
    const scope = '';
    // const state = generateHash(12);
    //
    // localStorage.setItem('sso_state', state);
    
    window.location.replace(
      ssoURI +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectURI}` +
      `&response_type=${responseType}` +
      `&scope=${scope}`
      // `&state=${state}`
    );
  }
  
  const ssoCheck = async (code: string) =>  {
    const response = await fetch(`${import.meta.env.VITE_BACKEND_API_ENDPOINT}/api/auth/user?code=${code}`).then(res => res.json()).catch(e => {
      console.log('error:', e);
    })
    console.log(response);
  }
  
  const auth = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    
    if (code) {
      await ssoCheck(code);
    }
  }
  
  return { user, auth, ssoRedirect };
}