import { ThemeConfig } from 'antd';

const colors = {
  primary: '#e7405a',
  
  white: '#FFF',
  light: '#F5F5F5',
  
  black: '#141414',
  dark: '#1F1F1F',
};

export const lightTheme: ThemeConfig = {
  token: {
    zIndexPopupBase: 100,
    colorPrimary: colors.primary,
    colorBgBase: colors.white,
    colorBgContainer: colors.white,
    colorBgElevated: colors.white,
    colorBgLayout: colors.light,
  },
  components: {
    Menu: {
      itemSelectedBg: '#0000000F',
      itemSelectedColor: '#000000E0',
    },
    Segmented: {
      itemSelectedBg: colors.white,
      trackBg: '#0000000A',
    },
    Layout: {
      bodyBg: colors.white,
      headerBg: colors.white,
      footerBg: colors.white,
      siderBg: colors.white,
      headerHeight: 56,
      headerPadding: '0px 32px 0px 28px',
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
      fontSize: 16,
    },
    Divider: {
      margin: 0,
    },
    Tree: {
      titleHeight: 56,
      nodeSelectedBg: '#0000000F',
      directoryNodeSelectedBg: '#0000000F',
    },
    Progress: {
      defaultColor: colors.primary,
    },
  },
};

export const darkTheme: ThemeConfig = {
  token: {
    zIndexPopupBase: 100,
    colorPrimary: colors.primary,
    colorBgBase: colors.dark,
    colorBgContainer: colors.dark,
    colorBgElevated: colors.dark,
    colorBgLayout: colors.black,
  },
  components: {
    Layout: {
      headerBg: colors.black,
      footerBg: colors.black,
      siderBg: colors.black,
      headerHeight: 56,
      headerPadding: '0px 32px 0px 28px',
    },
    Segmented: {
      itemSelectedBg: colors.dark,
      trackBg: colors.black,
    },
    Menu: {
      darkItemBg: colors.black,
      darkItemColor: colors.white,
      darkItemSelectedBg: '#FFFFFF1F',
      darkItemHoverBg: '#FFFFFF1F',
    },
    Input: {
      activeBg: colors.black,
    },
    Message: {
      zIndexPopup: 9999,
    },
    Modal: {
      contentBg: colors.black,
      footerBg: colors.black,
      headerBg: colors.black,
    },
    Popconfirm: {
      zIndexPopup: 9998,
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Divider: {
      margin: 0,
    },
    Tree: {
      titleHeight: 56,
    },
    Progress: {
      defaultColor: colors.primary,
    },
  },
};
