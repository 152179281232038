import { App } from 'antd';
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";

import './styles/index.scss';

import { withProviders } from "@/app/providers";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "@/app/route-tree/routeTree.gen.ts";

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('ru');

const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router)
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/v2\.instudy\.online/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const MyApp = () => {
  return (
    <App className='app'>
      <button style={{ height: 50, width: 200 }} onClick={() => {throw new Error("This is your first error!");}}>Break the world (Do not press)</button>;
      <RouterProvider router={router} />
    </App>
  );
};
const AppWithProviders = withProviders(MyApp);

export default AppWithProviders;