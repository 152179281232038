import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { ReactNode } from 'react';
import 'dayjs/locale/ru';

import { useTheme } from '@/entities/theme'

dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale('ru');

export const withAntConfigProvider = (component: () => ReactNode) => () => (
  <ConfigProvider
    locale={ru_RU}
    theme={useTheme().getThemeAntdConfig()}
    prefixCls='ins'
  >
    {component()}
  </ConfigProvider>
);
