import compose from 'compose-function';

import { withAntConfigProvider } from './withAntConfigProvider';
import { withAntStyleProvider } from './withAntStyleProvider.tsx';
import { withQueryClientProvider } from "./withQueryClientProvider.tsx";
import { withAuthProvider } from "./withAuthProvider.tsx";

export const withProviders = compose(
  withAntConfigProvider,
  withAntStyleProvider,
  withQueryClientProvider,
  withAuthProvider
);
