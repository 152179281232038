import { useState } from "react";
import { theme, ThemeConfig } from "antd";
import { darkTheme, lightTheme } from "../config/appTheme.ts";

export const useTheme = () => {
  const enum EThemes {
    LIGHT = 'light',
    DARK = 'dark'
  }
  
  const getInitialTheme = () => {
    let systemTheme: EThemes | null = null;
    
      systemTheme = matchMedia('(prefers-color-scheme: dark)').matches
          ? EThemes.DARK
          : EThemes.LIGHT
    
    const savedTheme = localStorage.getItem('theme');
    
    if (savedTheme) {
      return savedTheme as EThemes;
    } else {
      return systemTheme;
    }
  };
  
  const [currentTheme, setCurrentTheme] = useState(getInitialTheme());
  
  const faviconUpdate = (theme: EThemes) => {
    const link = document.querySelector('link[rel*="icon"]');
    
    if (link instanceof HTMLLinkElement) {
      link.href = `/favicon_${theme}.svg`;
    }
  }
  const themeMetaUpdate = (theme: EThemes) => {
    const existingThemeMeta = document.getElementById('theme-color');
    if (existingThemeMeta) {
      existingThemeMeta.remove();
    }
    
    const themeMeta = document.createElement('meta');
    
    themeMeta.id = 'theme-color';
    themeMeta.name = 'theme-color';
    themeMeta.content = theme === EThemes.LIGHT ? '#fff' : '#141414';
    
    document.head.appendChild(themeMeta);
  }
  const getThemeAntdConfig = (): ThemeConfig => {
    if (currentTheme === EThemes.DARK) {
      return {
        algorithm: theme.darkAlgorithm,
        ...darkTheme,
      };
    }
    
    return {
      algorithm: theme.compactAlgorithm,
      ...lightTheme,
    };
  }
  
  const changeTheme = (theme: EThemes) => {
    setCurrentTheme(theme);
    faviconUpdate(theme);
    themeMetaUpdate(theme);
    localStorage.setItem('theme', theme)
  }
  
  return { currentTheme, changeTheme, getThemeAntdConfig };
}