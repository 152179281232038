import { createRootRoute, Link, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { Layout } from "antd";
import { NotFound } from "@/shared/components/not-found";

// import { IUseAuth } from "@/entities/user";

// interface MyRouterContext {
//   auth: IUseAuth;
// }

export const Route = createRootRoute({
  component: () => (
    <Layout>
      <Layout.Header>
        <Link to={'/'} style={{ display: 'inline-block'}}>Главная</Link>
        <Link to={'/education'} style={{ display: 'inline-block'}}>Обучение</Link>
        <Link to={'/posts'} style={{ display: 'inline-block'}}>Посты</Link>
        <Link to={'/schedule'} style={{ display: 'inline-block'}}>Расписание</Link>
      </Layout.Header>
      <h1>Root</h1>
      <Outlet />
      <TanStackRouterDevtools />
      <Layout.Footer>Футер</Layout.Footer>
    </Layout>
  ),
  notFoundComponent: () => <NotFound />
})