import { Button, Flex, Result } from 'antd';

import { useStyles } from './styles.ts';
import { Link } from "@tanstack/react-router";


export const NotFound = () => {
  const { styles } = useStyles();

  const extra = (
    <Link to={'/'}>
      <Button type='primary'>Вернуться на главную</Button>
    </Link>
  );

  return (
    <Flex vertical align='center' justify='center' className={styles.container}>
      <Result
        status='404'
        title='404'
        subTitle='Запрашиваемая вами страница не найдена.'
        extra={extra}
      />
    </Flex>
  );
};
