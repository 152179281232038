/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../../pages/__root';
import { Route as ScheduleImport } from './../../pages/schedule';
import { Route as EducationImport } from './../../pages/education';
import { Route as LayoutImport } from './../../pages/_layout';
import { Route as IndexImport } from './../../pages/index';
import { Route as LayoutPostsImport } from './../../pages/_layout/posts';
import { Route as LayoutPostsPostIdImport } from './../../pages/_layout/posts.$postId';

// Create/Update Routes

const ScheduleRoute = ScheduleImport.update({
  id: '/schedule',
  path: '/schedule',
  getParentRoute: () => rootRoute,
} as any);

const EducationRoute = EducationImport.update({
  id: '/education',
  path: '/education',
  getParentRoute: () => rootRoute,
} as any);

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const LayoutPostsRoute = LayoutPostsImport.update({
  id: '/posts',
  path: '/posts',
  getParentRoute: () => LayoutRoute,
} as any);

const LayoutPostsPostIdRoute = LayoutPostsPostIdImport.update({
  id: '/$postId',
  path: '/$postId',
  getParentRoute: () => LayoutPostsRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/_layout': {
      id: '/_layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof LayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/education': {
      id: '/education';
      path: '/education';
      fullPath: '/education';
      preLoaderRoute: typeof EducationImport;
      parentRoute: typeof rootRoute;
    };
    '/schedule': {
      id: '/schedule';
      path: '/schedule';
      fullPath: '/schedule';
      preLoaderRoute: typeof ScheduleImport;
      parentRoute: typeof rootRoute;
    };
    '/_layout/posts': {
      id: '/_layout/posts';
      path: '/posts';
      fullPath: '/posts';
      preLoaderRoute: typeof LayoutPostsImport;
      parentRoute: typeof LayoutImport;
    };
    '/_layout/posts/$postId': {
      id: '/_layout/posts/$postId';
      path: '/$postId';
      fullPath: '/posts/$postId';
      preLoaderRoute: typeof LayoutPostsPostIdImport;
      parentRoute: typeof LayoutPostsImport;
    };
  }
}

// Create and export the route tree

interface LayoutPostsRouteChildren {
  LayoutPostsPostIdRoute: typeof LayoutPostsPostIdRoute;
}

const LayoutPostsRouteChildren: LayoutPostsRouteChildren = {
  LayoutPostsPostIdRoute: LayoutPostsPostIdRoute,
};

const LayoutPostsRouteWithChildren = LayoutPostsRoute._addFileChildren(
  LayoutPostsRouteChildren,
);

interface LayoutRouteChildren {
  LayoutPostsRoute: typeof LayoutPostsRouteWithChildren;
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutPostsRoute: LayoutPostsRouteWithChildren,
};

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '': typeof LayoutRouteWithChildren;
  '/education': typeof EducationRoute;
  '/schedule': typeof ScheduleRoute;
  '/posts': typeof LayoutPostsRouteWithChildren;
  '/posts/$postId': typeof LayoutPostsPostIdRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '': typeof LayoutRouteWithChildren;
  '/education': typeof EducationRoute;
  '/schedule': typeof ScheduleRoute;
  '/posts': typeof LayoutPostsRouteWithChildren;
  '/posts/$postId': typeof LayoutPostsPostIdRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/_layout': typeof LayoutRouteWithChildren;
  '/education': typeof EducationRoute;
  '/schedule': typeof ScheduleRoute;
  '/_layout/posts': typeof LayoutPostsRouteWithChildren;
  '/_layout/posts/$postId': typeof LayoutPostsPostIdRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | ''
    | '/education'
    | '/schedule'
    | '/posts'
    | '/posts/$postId';
  fileRoutesByTo: FileRoutesByTo;
  to: '/' | '' | '/education' | '/schedule' | '/posts' | '/posts/$postId';
  id:
    | '__root__'
    | '/'
    | '/_layout'
    | '/education'
    | '/schedule'
    | '/_layout/posts'
    | '/_layout/posts/$postId';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  LayoutRoute: typeof LayoutRouteWithChildren;
  EducationRoute: typeof EducationRoute;
  ScheduleRoute: typeof ScheduleRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LayoutRoute: LayoutRouteWithChildren,
  EducationRoute: EducationRoute,
  ScheduleRoute: ScheduleRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_layout",
        "/education",
        "/schedule"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/posts"
      ]
    },
    "/education": {
      "filePath": "education.tsx"
    },
    "/schedule": {
      "filePath": "schedule.tsx"
    },
    "/_layout/posts": {
      "filePath": "_layout/posts.tsx",
      "parent": "/_layout",
      "children": [
        "/_layout/posts/$postId"
      ]
    },
    "/_layout/posts/$postId": {
      "filePath": "_layout/posts.$postId.tsx",
      "parent": "/_layout/posts"
    }
  }
}
ROUTE_MANIFEST_END */
